<template>
  <div>
    <div class="d-flex justify-start align-center" style="height:50px;color: #d31145;background-color:#fff;padding: 0 0 0 10px">
      <v-icon color="#d31145" @click="backto">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">Buat Toko Anda</span>
    </div>

    <v-form>
      <div class="d-flex justify-center align-center py-8">
        <v-card
          flat
          class="pa-0 c-p"
          style="background: transparent;">
          <image-input v-model="avatar" type="uploadCover">
            <div slot="activator">
              <v-avatar
                tile
                size="125"
                class="grey lighten-1 cursor-pointer rounded-xl">
                <v-img
                  v-if="!avatar && addImage === ''"
                  :src="form.image_url"
                  class="mx-auto"
                  width="125"
                  height="125"
                  gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)">
                  <v-icon
                    v-if="!avatar && addImage === '' && form.image_url === ''"
                    color="white"
                    style="z-index:2"
                    size="40">
                    mdi-camera-plus
                  </v-icon>
                </v-img>

                <div
                  class="px-5"
                  v-if="addImage && avatar.url === undefined">
                  <v-progress-linear
                    color="#d31145"
                    indeterminate
                    rounded
                    height="6"
                    class="mx-4 mt-4"
                    style="width: 80px;">
                  </v-progress-linear>

                  <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                    Mengupload Gambar . . .
                  </p>
                </div>

                <v-img
                  v-if="avatar && !addImage"
                  :src="form.image_url"
                  :alt="form.image_url"
                  class="ma-auto"
                  gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                  width="125"
                  height="125">
                  <v-icon
                    color="white"
                    size="40"
                    v-if="avatar && !addImage">
                    mdi-camera-retake
                  </v-icon>
                </v-img>
              </v-avatar>
            </div>
          </image-input>
        </v-card>

        <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
          {{ error.image_url }}
        </p>
      </div>

      <v-container class="pa-6">
        <v-row>
          <v-col cols="12">
            <div class="subtitle-2 text-title font-weight-bold">
              Nama Outlet
            </div>

            <v-text-field
              v-model="form.name"
              placeholder="Tuliskan disini"
              required
              color="#d31145"
              maxlength="50"
              autocomplete="off"
              hide-details="auto"
              class="py-0"
              style="padding-top: 8px !important">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">
              <div class="text-required">
                Wajib
              </div>

              <div style="font-size: 11px;">
                <span style="font-weight: bold">
                  {{ form.name.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 50 Karakter
                </span>  
              </div>
            </div>
          </v-col>
          
          <v-col cols="12">
            <div class="subtitle-2 text-title font-weight-bold">
              Nomor Telepon Outlet
            </div>

            <v-text-field
              v-model="form.mobilephone"
              placeholder="Tuliskan disini"
              type="number"
              required
              color="#d31145"
              maxlength="15"
              autocomplete="off"
              hide-details="auto"
              class="py-0"
              style="padding-top: 8px !important">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">
              <div class="text-required">
                Wajib
              </div>
              <div style="font-size: 11px;">
                <span style="font-weight:bold">
                  {{ form.mobilephone.length }}
                </span>

                <span style="color:#B5B5B5"> 
                  dari 15 Karakter
                </span>  
              </div>
            </div>
          </v-col>
          
          <v-col cols="12">
            <div class="subtitle-2 text-title font-weight-bold">
              Alamat Outlet
            </div> 

            <v-text-field
              v-model="form.address"
              placeholder="Tuliskan disini"
              color="#d31145"
              maxlength="150"
              required
              autocomplete="off"
              hide-details="auto"
              class="py-0"
              style="padding-top: 8px !important">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">
              <div class="text-required">
                Wajib
              </div>

              <div style="font-size: 11px;">
                <span style="font-weight:bold">
                  {{ form.address.length }}
                </span>
                <span style="color:#B5B5B5"> 
                  dari 150 Karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <div style="position: absolute; bottom: 20px; width: 92%;">
              <v-btn 
                @click="toValidation()" 
                :loading="process.run" 
                :disabled="process.run" 
                block 
                rounded 
                large
                elevation="0" 
                color="#d31145" 
                class="body-1 d-flex white--text justify-start text-capitalize">
                <v-icon>mdi-check-circle-outline</v-icon>

                <span style="display:flex;justify-content:center;width:100%;">
                  Buka Toko
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- # BOTTOM SHEET COMFIRMATION  -->
    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          Daftar & Buka Toko
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin daftar dan buka toko ?
        </div>

        <div class="d-flex justify-center align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="saveStore()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya Daftar & Buka Toko 
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left"
        height="180px"
        width="100%">
        <div style="font-size:20px;color:#d31145; font-weight:bold;padding-left:20px;padding-top:20px">
          Daftar & Buka Toko
        </div>

        <div class="body-1 mb-2" style="padding:10px 20px 5px 20px">
          Apakah anda yakin ingin daftar dan buka toko ?
        </div>

        <div class="d-flex justify-star pa-5">
          <v-btn 
            @click="saveStore()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded 
            large
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya Daftar & Buka Toko 
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            width="150" 
            color="red"
            large
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false;$refs.observer.reset()">
            Batal
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet> -->
  </div>
</template>

<script>
import firebaseapp from "firebase";
import firebase from "@/plugins/Firebase";
import ImageInput from "@/components/ImageInput";
export default {
  components: {
    ImageInput,
  },
  data: () => ({
    confirmation: false,
    form: {
      name: "",
      address: "",
      mobilephone: "",
      image_url: ''
    },
    avatar: null,
    addImage: "",
    process: {
      run: false
    },
    error: {
      image_url: ""
    }
  }),
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  created() {

  },
  mounted(){
    this.avatar = {
      url: this.user.profile.image_url,
      add: false
    }
    this.form.name = this.user.profile.name;
    this.form.image_url = this.user.profile.image_url;
    this.addImage = false
  },
  methods: {
    toValidation(){
      if(!this.form.name){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nama Outlet tidak boleh kosong"
      }else if(!this.form.mobilephone){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor Telepon Outlet tidak boleh kosong"
      }else if(this.form.mobilephone.length < 10){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor Telepon Outlet tidak boleh kurang dari 10 karakter"
      }else if(!this.form.address){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Alamat Outlet tidak boleh kosong"
      }else {
        this.confirmation = true
      }
    },
    async saveStore(){
      this.$store.state.overlay.state = true;
      this.confirmation = false
      this.process.run = true;
      let data = {
        name: this.form.name,
        address: this.form.address,
        mobilephone: this.form.mobilephone,
        image_url: this.form.image_url,
        config_object: {}
      }

      let res = await this.$post(`pos/store/add`, data)
      if(res.status == 200) {
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        localStorage.setItem('user', JSON.stringify(res.results))
        window.location = `${process.env.BASE_URL}`
      }

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }

      this.process.run = false
      this.$store.state.overlay.state = false;
    },
    backto() {
      if (this.$store.state.authenticated && !this.$store.state.user.have_store_bool) {
        this.$post(`auth/logout`).then((response) => {
          if (response.status === 200){
            firebase.auth().signOut().then((response) => {
              firebaseapp
              .messaging()
              .getToken()
              .then((oldToken) => {
                if (oldToken === localStorage.getItem("TOKEN_FCM")) {
                  firebaseapp.messaging().deleteToken({ token: oldToken });
                  localStorage.setItem('TOKEN_FCM', '')
                } else {
                  console.log("Token is deleted");
                }
              });
            }).catch((error) => {
              // An error happened.
            })
  
            localStorage.clear()
  
            window.location = `${process.env.BASE_URL}`
          }
        })
      } else {
        this.$emit('close', false)
      }
    }
  }
}
</script>

<style>
 .v-responsive__content {
   width: 100% !important;
 }
</style>